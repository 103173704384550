.dashboard-scores{
    margin-top: 100px;
    height: 100vh;
    width: 90vw;
    margin: 100px auto;
    padding: 20px;
}
h4{
    line-height: 2em;
}
.dashboard-container{
    color: var(--clr-primary1);
    border: 0px solid blue;
}
.dashboard-scores{
    /* max-height: 400px; */
    /* overflow-y: scroll; */
    scrollbar-color: var(--clr-primary1) var(--clr-primary2);
    /* border-bottom: 1px solid var(--clr-primary1); */
}
.dashboard-scores::-webkit-scrollbar {
    width: 10px;
  }
  
  .dashboard-scores::-webkit-scrollbar-track {
    background: var(--clr-primary2);
  }
  
  .dashboard-scores::-webkit-scrollbar-thumb {
    background-color: var(--clr-primary1);
    border-radius: 20px;
    border: 3px solid var(--clr-primary2);
  }
  
.dash-txt{
    font-size: 1.15rem;
    line-height: 1.5em;
    color: var(--clr-text-dark);
}
.score-item{
    display: flex;
    border: 1px solid green;
}
