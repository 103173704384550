
.bannerCard-frontText {
    display: block;
    position: absolute;
    bottom: 20px;
    /* left: calc(50% - 1.25rem); */
    text-align: center;
    font-size: 3.5rem;
    font-weight: 600;
    color: var(--clr-text-light);
    border: 0px solid white;
    width: 100%;
    line-height: 3rem;
    border: 0px solid red;
}
.bannerCard-frontText:focus{
    color: var(--clr-text-light);
}
.bannerCard-backText {
    display: block;
    position: relative;
    width: 100%;
    margin-top: 40%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--clr-text-dark);
}
.colorBanner-front, 
.colorBanner-back{
    display: block;
    width: 150px;
    position:absolute;
    top: 0px;
    left: calc(50% - 75px);
    height: 150px;
    user-select: none;
    -webkit-user-select: none;
}
.colorBanner-back{
    height: 120px;
    color: var(--clr-primary1);
}
.bannerReverseText{
    position: absolute;
    bottom: 20px;
    display: inline-block;
    color: white;
    width: 100%;
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0 auto;
    text-align: center;
}

.banner-front,
.banner-back {
  position: absolute;
  top: 30px;
  width: 100%;
  height: 320px;
  backface-visibility: hidden;
}
.banner-back{
    top: 0px;
}
.back{
    border-radius: 20px;
    background: whitesmoke;
}