@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*,
*::before,
*::after{
  box-sizing: border-box;
}

:root {
  --clr-primary1: #4b50e2; 
  /* --clr-secondary1: #f07a02;  */
  --clr-secondary1: #f04d02; 
  --clr-tertiary: #10adec;
  --clr-success: #00d142;
  --clr-warning1: #FFc31c;
  --clr-danger1: #ff0090;
  --clr-text-dark: #4a4a4a;
  --clr-text-medium: #999;
  --clr-text-light: #fff;
  --ff-all: 'Poppins', sans-serif;
  --fw-bold: 700;
  --fw-regular: 400;
  --fw-light: 200;
  --bk-radial: radial-gradient(circle, rgb(239, 237, 237) 0%, rgba(157, 163, 243, 0.292) 50%, rgba(68, 6, 239, 0.222) 100%);
}

/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  padding: 0;
  font-size: 17pt;
  line-height: 1.15em;
  text-align: left;
  color: var(--clr-text-dark);
  overflow-x: hidden;
  background: radial-gradient(circle, rgb(239, 237, 237) 0%, rgba(157, 163, 243, 0.292) 50%, rgba(68, 6, 239, 0.222) 100%);
}
span:hover,
span:focus{
  color: var('clr-text-light')!important;
}
ul, li, p, h1, h2, h3, h4, section {
  margin: 0;
  padding: 0;
  text-align: left;
}
li {
  cursor: pointer;
}
.list-item-caret{
  color: var(--clr-text-medium);
}
a {
  text-decoration: none;
  color: inherit;
}

:active {
  color: var(--clr-secondary);
}
ul {
  list-style-type: none;
}
.section-title{
  color: var(-clr-primary1)
}
button{
  cursor: pointer;
  outline-style: none;
  border: none;
}
.container{
  width: 100%;
  height: 100%;
  margin-top: 200px;
  text-align: center;
  padding: 0 20px;
  border: 0px solid red;
}
.btn {
  background: none;
  /* border: 2px solid var(--clr-success); */
  padding: 6px 12px;
  border-radius: 4px;
  color: var(--clr-success);
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn:hover {
  background: var(--clr-success);
  color: #fff;
}
.btn-login{
  background-color: var(--clr-primary1);
  font-size: 1.25rem;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.btn-login:hover{
  background-color: var(--clr-success);
  color: white;
}
.pack-title{
  text-align: left;
}
.pack-thumb{
  margin-top: 10px;
  margin-right: 10px;
  max-width: 100px;
  height: 70px;
  border: 1px solid var(--clr-text-medium);
}
.snack-thumb{
  padding: 10px;
  border-radius: 3px;
  margin-right: 6px;
  background-color: #4b50e2;
  /* gradient blend at 45deg from --clr-warning to --clr-danger */
  /* background-image: linear-gradient(-45deg, var(--clr-danger), var(--clr-secondary)); */
}
.filterContainer{
  margin: 20px 20px auto;
  /* box-shadow: -2px -2px 6px #999; */
  border-radius: 8px;
}
.filterInput{
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid var(-clr-primary1);
  border-radius: 4px;
  outline: none;
  border-radius: 50%;
  color: var(--clr-text-light)
}

span:focus,
span:active,
span:hover {
  color: var(-clr-primary1);
}

.thumbsDownIcon{
  fill: 'var(--clr-danger)';
  width: '3rem';
  margin-left: '20px';
  margin-top: '10px';
}
.thumbsUpIcon{
  fill: 'var(--clr-success)';
  width: '3rem';
  margin-right: '20px';
  margin-top: '10px';
}
.quizDialogTitle{
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.quizDialogText{
  line-height: 1.5em;
  font-size: 1.15rem;
  width: 350px;
}
