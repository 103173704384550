.front{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imageCard {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.2);
    transition: all 0.5s ease;
}
.imageCard-backText {
    display: block;
    margin: 10px auto;
    text-align: center;
    font-size: 1.25rem;;
    font-weight: 500;
    color: var(--clr-text-dark);
}
.imageCard-frontText{
    position: relative;
    font-size: 1.20rem;
    font-weight: 500;
    line-height: 2;
    text-align: center;
    bottom: 20px;
    width: 90%;
    border: 0px solid red;
}
.medium-img{
    max-width: 200px;
    max-height: 150px;
    margin-top: 30px;
    margin-bottom: 30px;
    box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.2);
}
.small-img{
    width: 110px;
    margin: 20px calc(50% - 55px) auto;
    margin-bottom: 20px;
    box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.2);
}

