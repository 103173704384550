.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10000!important;
    transition: all 0.5s ease-out;
  }
  .modal.show {
    opacity: 1;
    pointer-events: auto;
  }
  .modal-content.show {
    transform: scale(1); /* Change the scale to 100% when the modal is shown */
  }
  .modal-content {
    position: relative;
    margin: 0 auto;
    width: 60%;
    max-width: 800px;
    height: 800px;
    max-height: 1000px;
    border-radius: 20px;
    background: whitesmoke;
    box-shadow: 0px 0px 0px rgb(253, 251, 251), 
    1px 2px 2px rgba(0, 0, 0, 0.05), 
    1px 4px 4px rgba(0, 0, 0, 0.1), 
    1px 10px 10px rgba(0, 0, 0, 0.25),
    1px 16px 16px rgba(0, 0, 0, 0.35);
    transform: scale(0.8); /* Add a scale transform to start at 80% size */
    transition: all 0.5s ease-out; /* Add a transition for the transform */
  }
  .video-container {
    position: absolute;
    top: 0px;
    width: 100%;
    background: black;
    justify-content: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    align-items: center;
  }
  .video-info {
    position: absolute;
    width: 80%;
    /* height: 40%; */
    margin: 50% 10% auto;
    padding: 10px;
    justify-content: center;
    align-items: center;
    color: var(--clr-text-dark);
    border: 0px solid red;
  }
  .video-title {
    margin-top: 140px;
    font-size: 2rem;
    line-height: 1.2em;
    color: var(--clr-primary1);
  }
  .video-runtime{
    margin-top: 10px;
    font-size: 1.15rem;;
    line-height: 1.2em;     
  }
  .video-description {
    margin-top: 20px;
    font-size: 1.25rem;
    line-height: 1.5em;
    opacity: 0.8;
  } 