.full-packs {
  margin-top: 100px;
  max-height: 40vh;
  border: 0px solid red;
}
.snack-packs,
.user-packs{
  margin-top: 50px;
  border: 0px solid blue;
}
.snack-packs{
  margin-top: 80px;
}
.tutorials{
  margin-top: 0px;
  margin-bottom: 100px;
}
.gallery-container{
  min-width: 800px;
  /* max-width: 1200px; */
}
.section-header{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items:last baseline;
  justify-content: space-between;
}
.gallery-title{
  margin: 20px 80px auto;
  color: var(--clr-primary1);
  font-size: 1em;
  font-weight: 600;
  line-height: 1.5em;
}
.view-all{
  margin-top:50px;
  margin-right: 30px;
  color: var(--clr-primary1);
  font-size: .8em;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none  !important;
}
.pack-card{
  margin: 10px;
  width: 100%;
  height: 44vh;
  cursor: pointer;
  border: 0px solid red;
}
.pack-title{
  font-size: .85em;
  font-weight: 500;
  text-align: center;
  color: var(--clr-text-dark);
  border: 0px solid orange;
}
.tutorial-card{
  margin: 0px;
  width: 100%;
  height: 40vh;
  cursor: pointer;
  border: 0px solid red;
}
.tutorial-title{
  font-size: .85em;
  font-weight: 500;
  text-align: center;
  color: var(--clr-text-dark);
  border: 0px solid orange;
}
.tutorial-cover{
  width: 350px;
  max-height: 30vh;
  border-radius: 20px;
  border: 0px solid blue;
  box-shadow: 3px 3px 12px rgba(75,80,226,.4);
  object-fit: cover;
  background: rgb(29, 5, 100);
}
.pack-cover{
  width: 100%;
  height: 38vh;
  max-height: 30vh;
  border-radius: 20px;
  border: 0px solid blue;
  box-shadow: 3px 3px 12px rgba(75,80,226,.4);
  object-fit: cover;
}
.pack-thumb{
  width: 100px!important;
  max-height: 70px;
  overflow-y: hidden;
}
.snack-card{
  width: 90%;
  min-height: 18vh;
  margin: 10px;
  cursor: pointer;
  text-align: center;
  cursor: pointer;
  border: 0px solid red;
}
.snack-icon-holder{
  width: 90%;
  height: 150px;
  margin-left: 5%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(75,80,226);
  background: linear-gradient(-40deg, rgb(50, 54, 177) 0%, rgb(223, 29, 253) 60%, rgb(255, 157, 28) 97%);
  box-shadow: 3px 3px 12px rgba(75,80,226,.4);
}
.snack-icon{
  width: 80px;
  height: 80px;
  color: red;
  fill: white!important;
}
.snack-title{
  display: inline-block;
  margin-left: 0px;
  width: 150px;
  margin-top: 6px;
  font-size: .95rem;
  font-weight: 500;
  line-height: 1.5em;
  text-align: center;
  white-space: nowrap;
  color: var(--clr-text-dark);
  border: 0px solid orange;
}
.list-container{
  margin-top: 200px;
  padding-bottom: 150px;
}
.packs-list{
  position: absolute;
  margin-bottom: 150px!important;
  top: 130px;
  left: 20px;
  border: 0px solid red;
}
.listNav{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  margin:60px auto;
  margin-right: auto;
  width: 100%;
  height: 60px;
  background: var(--clr-primary1);
  opacity: 0.9;

  z-index: 1000;
}
.listNavTitle{
  display: block;
  width: 90%;
  margin-left: 36px;
  color: var(--clr-text-light);
  line-height: 3em;
  border: 0px solid red;
}


/* //ribbon for multi-lingo packs */
.lingoRibbon {
  width: 100px;
  display: block;
  position: absolute;
  top: 50%;
  left: -5px;
  padding: 4px 4px;
  font-size: 1rem;
  color: white;
  text-align: right;
  background: var(--clr-primary1);
  box-shadow: 0 1px 1px rgba(0,0,0,0.25), 
  0 2px 2px rgba(0,0,0,0.20), 
  0 4px 4px rgba(0,0,0,0.15), 
  0 8px 8px rgba(0,0,0,0.10),
  0 16px 16px rgba(0,0,0,0.05);
  z-index: 1000;
}
.bottomSpacer{
  height: 100px;
}

