.login-container{
  width: 100%;
  height: 100%;
  border: 0px dotted red;
}
.login-header{
  margin-top: 100px;
  width: 100%;
  height: 100px;
  text-align: center;
  border: 0px dotted orange;
}
.login-title, .login-sub-title{
  margin: 0;
  padding: 0;
  text-align: center;
}
.login-title{
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--clr-primary1);
}
.login-sub-title{
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 3em;
  color: var(--clr-primary1);
}
.login-wrapper{
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border: 0px dotted blue;
  box-shadow: 4px 4px 4px 4px rgba(0,0,0,0.2);
}
.section-title{
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--clr-primary1);
}
.login-logo{
  min-width: 260px;
  max-width: 260px;
  margin-left: 70px;
}
.login-form {
  min-width: 400px;
  max-width: 400px;
  padding: 50px;
  border-left: 2px dotted #ccc;
}
.login-form label {
  display: block;
  font-size: 1.2rem;
  margin: 30px auto;
}
.login-form span {
  display: block;
  margin-bottom: 6px;
}
.login-form input {
  padding: 8px 6px;
  font-size: 1.25rem;
  width: 100%;
}
.loginInput{
  font-size: 20px!important;
}
.btn-login{
  width: 100%;
}