  .createCardContainer{
    margin-top: 90px;
    flex-wrap: nowrap;
    width: 400px;
    height: 350px;
    padding: 20px;
    border-radius:10px;
    background: whitesmoke;
    box-shadow: 3px 3px 8px rgba(50,50,50,0.1);
    border: 0px solid black;
  }
  .createCardForm{
    margin: 0px auto;
    max-width: 350px;
    opacity: 1;
    background: whitesmoke;
  }
  .frontTextField{
    width: 100%;
    border-radius: 5px;
    border: 0px solid red;
  }
  .backTextField{
    margin-top: 30px;
    width: 100%;
    border-radius: 5px;
  }
  .studio-form-header{
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: center!important;
    border: 0px solid blue;
  }
  .studio-form-header-title {
    color: var(--clr-primary1);
    font-weight: 600;
  }
  .studio-form-label{
    margin-top: 0px;
    margin-bottom: -14px;
    display: block;
    color: var(--clr-text-dark);
    font-size: 0.8rem;
    font-weight: 400;
    text-transform: uppercase;
    opacity: 0.9;
    border: 0px solid red;
  }
  .submitBtnContainer{
    margin-top: 20px;
    width: 150px;
  }
  .studio-input-container{
    margin-top: 10px;
    border: 0px solid red;
  }
  .help-text-container{
    display: block;
    width: 100%;
    margin-top: -10px;
    line-height: 1.15em;
    border: 0px solid red;
  }
  .studio-help-text{
    display: inline-block;
    margin: 4px 70px 20px 4px!important;
    font-size: .9rem;
    font-weight: 400;
    color: var(--clr-primary1);
    border: 0px dotted green;
  }
  .studio-help-text-counter{
    display: inline-block;
    position: relative;
    line-height: 1.5em;
    /* margin: -8px 0px 0px 4px; */
    text-align: right;
    font-size: 1rem;
    font-weight: 400;;
    color: var(--clr-primary1);
    border: 0px solid red;
  }
  .studio-help-text-green{
    display: block;
    margin: 4px 0px 10px 4px;
    font-size: 1rem;
    font-weight: 400;;
    border: 0px dotted green;
    color: var(--clr-success);
  }
  .submitBtnContainer{
    position: absolute;
    border: 0px solid red;
    height: 40px;
    width: 200px;
    bottom:30px;
  }
  .submitBtn{
    width: 100%;
    background: var(--clr-tertiary)!important;
  }
  .submitBtn:hover{
    background: var(--clr-success)!important;
    color: white;
  }
  