
.studio{
    width:100vw;
    overflow: hidden;
    background: var(--bk-radial-gradient);
}
.studio-container{
    width: 100vw;
    margin: 0 auto;
    padding-top: 0px;
    color: var(--clr-text-light);
    border: 0px dotted red;
    overflow: hidden!important;
}
.studio-nav{
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    width: 100%;
    height: 50px;
    background-color: var(--clr-tertiary);
    z-index: 1000;
    border: 0px solid white;
}
.studio-nav-start,
.studio-nav-middle,
.studio-nav-end {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 30%;
  border: 0px dotted white;
}

.studio-nav-start {
  justify-content: flex-start;
  padding-left: 80px;
  width: 'fit-content';
  max-width: 16vw;
}

.studio-nav-end {
  justify-content: flex-end;
  padding-right: 0px;
  margin-right: -20px;
}

.studio-content{
    display: grid;
    grid-template-columns: auto 300px;
    border: 0px dotted blue;
}
.addCardInput{
    margin-top: 10px;
    width: 100%;
    border-radius: 5px;
    margin: 10px 0;
}
.create-cards-grid {
    margin-top: 120px;
    display: grid;
    grid-template-columns: auto 450px;
    column-gap: 80px;
    min-width: 980px; /* add this to prevent the columns from overlapping on smaller screens */
    border: 0px solid red;
  }
  .left-column {
    height: 100vh;
    align-content: top;
    padding: 0;
    margin-top: -300px;
    margin-left:74px;
    overflow-y: scroll;
    border: 0px solid green;
  }
  
  .right-column {
    position: fixed;
    left: calc(50vw + 30px); /* adjust the value of 80px to match your desired distance between the columns */
    top: 94px;
    min-width: 450px;
    width: 450px;
    border: 0px solid blue;
    display: flex;
    justify-content: left;
    align-items: top;
  }
  .addPackButton{
    position: absolute;
    right: 72px;
    border: 0px solid white;
  }
  .practice-button{
    position: absolute;
    left: 90%;
    font-size: 1rem!important;
    border-radius: 5px;
    border: 2px solid var(--clr-primary1);
    margin: 0 auto;
    cursor: pointer;
    z-index: 1000;
  }


  

