.score-content{
    display: grid;
    grid-template-columns: 3fr 1fr 3fr 1fr;
    grid-template-rows: 1fr;
    color: var(--clr-text-dark);
    width: 100%;
    align-items: center;
    border-bottom: 1px dotted var(--clr-text-medium);
}
.score-txt{
    font-size: 1.15rem;
    line-height: 2.5em;
    color: var(--clr-text-dark);
    border: 0px solid blue;
}
