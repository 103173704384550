
.warning-dialog {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    padding: 24px;
    text-align: center;
    max-width: 400px;
    height: 400px!important;
  }
  h2#dialog-title{
    color: var(--clr-danger1)!important;
    font-weight: 500;
  }
  .warning-dialog h2 {
    font-size: 24px;
    margin-bottom: 12px;
  }
  
  .warning-dialog p {
    font-size: 16px;
    margin-bottom: 24px;
  }
  
  .warning-dialog-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .warning-dialog-primary-button,
  .warning-dialog-secondary-button {
    background-color: #fff;
    border: 2px solid #3ff436;
    border-radius: 4px;
    color: #5c36f4;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin-right: 12px;
    padding: 8px 16px;
    transition: all 0.2s ease-in-out;
  }
  
  .warning-dialog-primary-button:hover,
  .warning-dialog-secondary-button:hover {
    background-color: #f4ee36;
    color: #fff;
  }
  
  .warning-dialog-primary-button:focus,
  .warning-dialog-secondary-button:focus {
    outline: none;
  }
  