.signup-form {
  max-width: 40vw;
  margin: 120px auto;
  padding: 40px;
  border: 1px solid var(--clr-primary1);
}
.signup-form label {
  display: block;
  margin: 30px auto;
}
.signup-form span {
  display: block;
  margin-bottom: 6px;
}
.signup-form input {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
}
.btnSignup{
  background-color: #4CAF50;
  border-radius: 5px;
  color: white;
  padding: 8px 6px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 1em;
}
.btnSignup:hover{
  background-color: #45a049;
}
.loginInput{
  color: var(--clr-text-dark)!important;
  width: 100%;
}