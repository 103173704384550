
.card-container {
    display: block;
    position: absolute; 
    top: -1px;
    width: 350px;
    height: 250px;
    cursor: pointer;
    border-radius: 20px;
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgb(240, 239, 239) 100%);
  }
  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border-radius: 20px;
    border: 1px solid #cdcdcd;
  }
  .card.flipped {
    transform: rotateY(180deg);
  }
  .front,
  .back {
    position: absolute;
    top: 0;
    width: 100%;
    height: 220px;
    backface-visibility: hidden;
    user-select: none;
    -webkit-user-select: none;
    border: 0px solid red;
  }
  .back {
    transform: rotateY(180deg) translateZ(1px);
  }
  .buttons{
    position: absolute;
    margin-bottom: -110px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 0px solid red;
  }
  .lingoCard-frontText, 
  .lingoCard-backText{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 5%;
    padding-top: 10%;
    text-align: center;
    font-size: 1.35rem!important;
    line-height: 1.4em;
    font-weight: 400;
    color: var(--clr-text-dark);
    border: 0px solid blue;
}

.lingoCard-backText{
  border: 0px solid red;
  border-radius: 20px;
  background: whitesmoke;
}

.phonetics{
  position: absolute;
  top: 20px;
  font-size: 1.125rem;
  color: var(--clr-primary1);
  left: 5%;
  text-align: center;
  width: 90%;
  border: 0px solid green;
}

.button-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: -20px;
  margin-left: 20px;

  width: 90%;
}

.thumbs-icon {

  width: 3rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  fill: var(--clr-secondary1);
}

.thumbsup {
  fill: var(--clr-success);
  margin-left: auto;
}

.thumbsdown {
  margin-right: auto;
}
  