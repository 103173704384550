.studio-nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: left;
    padding-right: 50px;
}
.modal-container{
    max-width: 600px;
    min-width: 500px;
    border-radius: 15px;
    background: linear-gradient(45deg, rgb(240, 239, 241) 0%, rgb(207, 188, 254) 100%);
}
.create-pack-form-header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: var(--clr-text-light);
    background: var(--clr-primary1);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.user-text{
    font-size: 1.15rem;
    line-height: 1.5em;
    margin-top: 10px;
}
.create-pack-form{
    display: block;
    /* padding-top: 20px!important;
    padding-bottom: 40px!important; */
    border: 0px solid red;
    background: linear-gradient(45deg, rgb(240, 239, 241) 0%, rgb(207, 188, 254) 100%);
}
.form-label{
    /* margin-top: 30px; */
    display: block;
    font-size: 1rem;
    font-weight: 600;
    line-height: 2em;
    color: var(--clr-primary1);
    text-transform: uppercase;
}
.form-voices{
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: none!important;
    line-height: 3rem;
    padding: 10px;
    font-size: 1rem;
}
.voice-selector {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    padding-right: 10px;
    margin-top: 10px;
  }
  .voice-dropdown{
    width: 50%;
  }
  .voice-label {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    line-height: 2em;
    color: var(--clr-primary1);
    text-transform: uppercase;
    grid-column: 1 / span 2;
  }
.create-pack-form {
    margin: 0 auto;
    padding: 4%;
    width: 100%;
    max-width: 80vw;
    /* background: var(--bk-radial); */
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

/* .image-list-thumb:hover{
    opacity: .5;
    border: 3px solid var(--clr-secondary1);
}
image-list-thumb:visited{
    border: 3px solid var(--clr-secondary1);
} */
.myPlayBtn{
    margin: 5px 5px auto;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: var(--clr-primary1);
}
.myPlayBtn:hover{
    color: var(--clr-success);
}
.coverSelector{
    width: 100%;
    height: 'fit-content';
    border: 2px solid red;
}