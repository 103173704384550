.textCard-frontText, 
.textCard-backText{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 5%;
  padding-top: 10%;
  text-align: center;
  font-size: 1.35rem!important;
  line-height: 1.4em;
  font-weight: 400;
  color: var(--clr-text-dark);
  opacity: 1;
  user-select: none;
  -webkit-user-select: none;
  border: 0px solid blue;
}
.textCard-phonetics{
  color: var(--clr-text-primary1);
}
