.Toastify__toast {
  background: var(--clr-success);
  color: #fff;
  font-weight: bold;
}
.audio-popover{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.deck-content-container{
  display: grid;

  height: 80vh;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-gap: 10px; 
  border: 0px dotted red;
}
.score-container{
  grid-row: 1;
  margin-top: -60px;
  width: 100%;
  min-height: 200px;
  border: 0px dotted blue;
}
.deck-container{
  margin-top: -70px;
  grid-row: 2;
  width: 100%;
  min-height: 200px;
  /* object-fit: cover; */
  border: 0px dotted green;
}
.btn-quiz{
  grid-row: 3;
  margin-top: -70px;
  width: 100%;
  height: 80px;
  border: 0px dotted orange;
}
/*-- this is also the card container */
.deck-container {
  display: block;
    position: absolute;
    left: calc(50% - 175px);
    top: 350px;
    width: 350px;
    height: 248px;
    background-color: none;
    background: radial-gradient(circle, rgb(239, 237, 237) 0%, rgba(157, 163, 243, 0.292) 50%, rgba(68, 6, 239, 0.222) 100%);
    border-radius: 20px;
    box-shadow: 0px 0px 0px rgb(253, 251, 251), 
    1px 2px 2px rgba(110, 110, 110, 0.05), 
    1px 4px 4px rgba(110, 110, 110,0.10), 
    1px 8px 8px rgba(110, 110, 110,0.15),
    1px 16px 16px rgba(110, 110, 110,0.20); 
  }
  .deckNav {
      display: flex;
      position: fixed;
      top: 62px;
      width: 100%;
      min-width: 500px;
      height: 3.25rem;
      padding-left: 20px;
      padding-right: 20px;
      background: var(--clr-tertiary);
  }
  .deckNav-content{
    color: white;
    color: var(--clr-text-light);
    padding-left: 15px;
    line-height: 3rem;
  }
  .score-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    top: 200px;
    left: calc(50% - 180px);
    width: 370px;
    min-height: 120px;
    text-align: center;
  }
  .scoreComp{
    width: 170px;
    margin-top: -40px;
    height: 100px;
    border: 0px solid blue;
    text-align: center;
  }
  .score-label{
    display: block;
    font-size: 1rem;
    line-height: 4rem;
    font-weight: 500;
    margin-bottom: .5rem;
    color: var(--clr-dark);
    opacity: .6;
  }
  .score-label:focus{
    color: black
  }
  .scoreComp h1{
    font-size: 4rem;
    font-weight: 600;
    text-align: center;
    text-shadow: 0 1px 1px rgba(116, 116, 116, 0.08), 
    0 2px 2px rgba(116, 116, 116,0.12), 
    0 4px 4px rgba(116, 116, 116,.16), 
    0 6px 6px rgba(116, 116, 116,.20);;
  }
  .present-score-container{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    top: 20%;
    left: calc(50% - 180px);
    width: 370px;
    height: 120px;
    text-align: center;
    border: 0px solid red;
  }
  .btn-quiz{
    position: absolute;
    top: 650px;
    margin-left: calc(50% - 160px);
    width:320px;
    height: 40px;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 10px;
    z-index: 101;
    background: var(--clr-primary1);
    box-shadow: 0 1px 1px rgba(50, 50, 50, 0.25), 
    0 2px 2px rgba(116, 116, 116,0.20), 
    0 4px 4px rgba(116, 116, 116,0.15), 
    0 8px 8px rgba(116, 116, 116, 0.10),
    0 16px 16px rgba(116, 116, 116, 0.05); 
  }



