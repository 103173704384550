/* navbar styles */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background: var(--clr-primary1);
  padding: 10px 10px;
  box-sizing: border-box;
  color: white;
  z-index: 100;
}
.navbar ul {
  display: flex;
  /* margin: 0 auto; */
  /* max-width: 960px; */
  align-items: center;
}
.navbar li {
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 2.5rem;
  padding: 4px 20px auto;
  color: white;
  width: 150px;
}
.navbar li:nth-of-type(1){
  width: 160px;
  margin-right: 10px;
}
.navbar-title {
  /* margin-left: 10px; */
  margin-right: auto;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1.1em;
}
.navbar-links{
  display: inline-block;
  font-weight: 600;
  justify-content: center;
  align-self: center;
  cursor: pointer;
  /* margin-left: 10px; */
  margin-right: auto;
  border: 0px solid white;
}
.navbar-link{
  margin: 8px 10px auto;
}
.navbar button, .navbar a {
  margin-left: 16px;
  color: var(--clr-text-light);
  opacity: .5;
  border-color: white;
}
.navbar a {
  display: block;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  font-size: 1em;
}

.navbar a:hover{
  background: white;
  color: var(--clr-primary1);
  font-weight: 700;
  opacity: 1;
}

.navbar a.active {
  color: var(--clr-text-light);
  opacity: 1;
  /* border-bottom: 4px solid white; */
}

.navbar a.active:hover {
  color: var(--clr-primary1);
  opacity: 1;
  /* border-bottom: 4px solid white; */
}

.btn {
  background: none;
  /* border: 2px solid var(--clr-success); */
  padding: 6px 12px;
  border-radius: 4px;
  color: var(--clr-success);
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn:hover {
  background: white;
  color: var(--clr-primary1);
  opacity: 1;
}

