
.create-pack-container{
    max-width: 100%;
    min-width: 500px;
    color: var(--clr-text-dark);
    border-radius: 15px;
    overflow: hidden;
    border: 0px solid blue;
    background: var(--bk-radial);
}
form.create-pack-form{
    margin: 0;
    padding: 0;
    margin: 20px auto;
    width: 90%;
    height: 400px;
    background: transparent;
}
.form-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: left;
    height: 340px;
    width: 100%;
    color: var(--clr-text-dark);
    overflow: hidden;
    border: 1px dotted var(--clr-primary1);
}
.left-col{
    width: 100%;
    border: 0px solid red;
}
.right-col{
    display: block;
    margin: 0 6px auto;
    width: 100%;
    border: 0px solid blue;
}
.selectedImage {
    opacity: 1;
}
.selectedImageBorder {
    border: 4px solid var(--clr-success);
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(69, 244, 25, 0.5);
    z-index: 1;
    display: none;
  }
.selected .overlay {
    display: block;
  }
.new-pack-form-label{
    margin-top: 4px;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: -4px;
    display: block;
    color: var(--clr-text-dark);
    font-size: 0.8rem;
    font-weight: 400;
    text-transform: uppercase;
    opacity: 0.9;
    border: 0px solid red;
}
.submit-form-button{
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    margin-left: 70%;
    width: 30%;
    height: 40px;
    background-color: var(--clr-primary1);
    color: var(--clr-text-light);
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
}
.submit-form-button:hover{
    background-color: var(--clr-success);
}

.newPack-formControl{
    margin: 0;
    width: 100%;
    border: 0px solid red;
}
.test-audio-button:hover{
    background: var(--clr-success)!important;
    color: var(--clr-text-light);
}