.sidebar-bottom-nav{
    position: fixed;
    bottom: 0px;
    width: 400px;
    height: 0px;
    background: var(--clr-tertiary);
}
.my-packs-list{
    margin-top: 60px!important;
    padding-bottom: 140px!important;
    overflow-y: scroll!important;
}
.drawer-nav{
    display: inline-block;
    /* position: fixed; */
    left: 0;
    top: 50px;
    width: 400px;
    height: 50px;
    background: var(--clr-tertiary);
    z-index: 1000;
}
.avatarContainer{
    width: 100px;
    height: 80px;
    overflow: hidden;
}
.pack-thumb{
    width: 100%;
    height: auto;
    overflow: hidden;
}
.list-item-text{
    color: 'var(--clr-text-dark)';
    margin-left: 6px;
}
.drawer-help-text{
    color: var(--clr-text-dark);
    font-size: 12px;
    font-size: 1.35rem;
    line-height: 2rem;
    margin: 10px 20px auto;
    padding: 20px;
}
